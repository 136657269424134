
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: white;
}
