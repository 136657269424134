.Hero{
    /* #e1e8f5 */
    background-color: #F1EAFF;
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.dev-info{
    width: 300px;
    
}
.picture:not(.img-pic){
    width: 254px;
    height: 254px;
    border-radius: 50%;
    border: #ff6600 solid 4px;
    position: relative;
    animation-name: border-anim;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    opacity: 0;

}
.picture .img-pic{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    transition: .5s ease-in-out;
    opacity: 1;
}
.picture img:hover{
    transform: rotate(360deg);
}
.btn-resume{
    border: solid 1px white;
    color: white;
    border-radius: 10px 3px 10px 3px;
    padding: 8px 6px;
    background-color: #ff6600; 
    position: absolute;
    top: -200px;
    border-color: white 1px solid;
    transition: .5s ease-in-out;
    cursor: pointer;
    box-shadow: 5px 3px 5px rgba(0,0, 0, 0.5);
    animation-name: btn-res;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.btn-resume:hover{
 
    background-color: #192655;
    border-radius: 3px 10px 3px 10px;
}

.btn-contact{
    border: solid 1px white;
    color: white;
    border-radius: 10px 3px 10px 3px;
    padding: 8px 6px;
    background-color: #192655; 
    position: absolute;
    left: -200px;
    border-color: white 1px solid;
    transition: .5s ease-in-out;
    cursor: pointer;
    box-shadow: 5px 3px 5px rgba(0,0, 0, 0.5);
    animation-name: btn-co;
    animation-delay: 2s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
.btn-contact:hover{
 
    background-color: #ff6600;
    border-radius: 3px 10px 3px 10px;
}
@keyframes border-anim{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
        
    }

}
@keyframes btn-res{
    0%{
        top: -200px;
    }
    100%{
        top: 280px;
    }
}
@keyframes btn-co{
    0%{
        left: -200px;
    }
    100%{
        top: 280px;
        left: 320px;
    }
}
@media screen and (max-width: 700px) {
    .dev-info{
        width: 200px;
        margin-left: 10px;
    }
    .picture .img-pic{
        width: 150px;
        height: 150px;
    }
    .picture:not(.img-pic){
        width: 160px;
        height: 160px;
    }
    @keyframes btn-co{
        0%{
            left: -200px;
        }
        100%{
            top: 280px;
            left: 140px;
        }
    }
}
@media screen and (max-width: 400px) {
    .dev-info{
        width: 200px;
        margin-left: 10px;
    }
    .picture .img-pic{
        width: 100px;
        height: 100px;
    }
    .picture:not(.img-pic){
        width: 110px;
        height: 110px;
    }
    @keyframes btn-co{
        0%{
            left: -200px;
        }
        100%{
            top: 300px;
            left: 140px;
        }
    }
    @keyframes btn-res{
        0%{
            top: -200px;
        }
        100%{
            top: 300px;
           
        }
    }
}
@media screen and (max-width: 300px){
    .dev-info{
        width: 200px;
        margin-left: 2px;
        font-size: 12px;
    }
    .picture .img-pic{
        width: 70px;
        height: 70px;
    }
    .picture:not(.img-pic){
        width: 75px;
        height: 75px;
    }
}