header{
    width: 100%;
    background-color: #192655;
    -webkit-box-shadow:0px 1px 1px #192655;
    -moz-box-shadow:0px 1px 1px #192655;
    box-shadow:0px 1px 1px #192655;
    position: sticky;
    top: 0;
    z-index: 3000;
}
.nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
    font-size: 14px;
}
.menu{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  
}
.menu .menu-item{
    position: relative;
   
}
.menu .menu-item{
    padding: 20px 0;
}
.menu .menu-item .link{
    transition: .5s ease-in-out;
    cursor: pointer;
}
.menu .menu-item .link:hover{
    color:#ff6600;
 
}
.menu .menu-item:hover .dropdown{
    display: block;
    opacity: 0;
    height: 0;
    animation-name: dropdownt;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}
.logo{
    color: white;
    margin-left: 20px;
    font-size: 1rem;
    font-weight: 300;
    
}
.dropdown{
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 180px;
    background-color: #192655;
    border-top: solid 2px #ff6600;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
    z-index: 1200;
}
.dropdown .menu-item {
    padding: 10px;
   
}
.dropdown .menu-item:not(:last-child){
    border-bottom: solid 1px #0e1942;  
}
/* hamburger menu style */
.hamburger{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-radius: 50%;
    text-align: center;
    display: none;
}
.bar{
    width: 25px;
    height: 3px;
    display: block;
    margin-top: 4px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 5px rgba(0, 0, 0, .5);
    
}
@keyframes dropdownt{
    0%{
        opacity: 0;
        height: 0;
    }
    100%{
        height: 90px;
        opacity: 100%;
    }
}
/** media queris to build responsive website */
@media screen and (max-width: 768px){
    header{
        position: relative;
        padding: 1rem 10px;
        width: 100vw;
        max-width: 100%;
        z-index: 1000;
        height: 70px;
    }
   
    .hamburger{
        display: block;
        position: absolute;
        right: 10px;
        top: 17px;
        cursor: pointer;
    }
  
    .menu{
    position: absolute;
    justify-content: flex-start;
    gap: 2%;
    flex-direction: column;
    background-color: #0e1942;
    width: 100vw;
    left: 0;
    top: -1000px;
    height: calc(100vh - 70px);
    transition: .5s ease-in-out;
    margin-right: 0px;
    max-width: 100%;
    z-index: -1;
    }

    .menu.active{
        position: absolute;
        top: 100%;
        left: 0;
    }
  
    .hamburger.active span:nth-child(3)
    {
        transform: rotate(-45deg) translatey(-9.5px);
        transition: .5s;
    }
    .hamburger.active span:nth-child(2)
    {
        opacity: 0;
    }
    .hamburger.active span:nth-child(1)
    {
        transform: rotate(45deg) translateY(9.5PX);
        transition: .5s;
    }
    .dropdown{
        left: 10px;
        top: 100%;
        background-color: inherit;
    }
    
   
}