.port-container{
    width: 100%;
    background-color: #F1EAFF;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #192655;
}
.port-header{
    text-align: center;
}
.port-header h2{
    position: relative;
}
.port-header h2::after{
    content: '';
    position: absolute;
    text-align: center;
    width: 45px;
    height: 3px;
    left: 48%;
    bottom: -1px;
    background-color: #ff6600;
}
.card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-left: 20px;
    

}

.card{
    width: 500px;
    height: 310px;
    box-shadow: 2px 2px 2px 1px rgba(0,0,0,.5);
    background-color: white;
    border-radius: 10px;
    position: relative;
    margin-left: 50px;
    margin-top: 20px;
    line-height: 20px;

}
.card .card-header{
    position: absolute;
    height: 48px;
    width: 100%;
    background-color: #0766AD;
    color: white;
    text-align: center;
    padding-top: 10px;
    font-weight: 300;
    font-size: 13px;
}
.card .card-footer{
    position: absolute;
    bottom: 10px;
    left: 20px;
}
.card .card-body{
    color: black;
    position: absolute;
    top: 50px;
    left: 10px;
    font-size: 12px;
}
.card-body .title{
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
}
.card-body p{
    padding-top: 4px;
    font-size: 11px;
    text-align: justify;
    padding-right: 10px;
}
.view-detail{
    margin-top: 10px;
    margin-right: 5px;
    padding: 6px 6px;
    border: none;
    font-size: 12px;
    background-color: green;
    border-radius: 5px;
    font-weight: 500;
    color: white;
    border:  1px solid white;
    box-shadow: 2px 2px rgba(0,0,0,.6);
    cursor: pointer;
    transition: .5s ease-in-out;

}
.view-detail:hover{
    background-color: #192655;
    color: white;
    
}
.btn-primary{
    margin-top: 10px;
    margin-right: 5px;
    padding: 6px 6px;
    border: none;
    font-size: 12px;
    background-color: crimson;
    border:  1px solid white;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    box-shadow: 2px 2px rgba(0,0,0,.6);
    transition: .5s ease-in-out;
    cursor: pointer;
}
.btn-primary:hover{
    background-color: #ff6600;
    color: white;
    color: black;
}
.video-container{
    display: none;
    position: fixed;
    top: 20vh;
    left: 20px;
    z-index: 2000;
}
.video-container.active{
    display: block;
}

.video-cont{
    position: relative;
    border-radius: 5px;
    box-shadow: 3px rgba(0,0,0,.5);
    width: 600px;
    height: 500px;
    background-color: black;
}
.video-container video{
    position: absolute;
    width: 100%;
    top: 60px;
}

.video-container .close{
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 50%;
    top: 5px;
    right: 10px;
    font-size: 16px;
    color: red;
    z-index: 3000;
    cursor: pointer;
}
.close i{
    padding-left: 5px;
}
@media screen and (max-width: 500px){
    .card{
        width: 96%;
        height: 335px;
        margin-left: 0px;
    }
    .video-container{
        display: none;
        position: fixed;
        top: 5vh;
        left: 20px;
        z-index: 3000;
    }
    .video-cont{
        position: relative;
        width: 92vw;
        height: 40vh;
      
    }
    .video-container video{
        position: absolute;
        width: 100%;
        top: 50px;
    }
    
}
@media screen and (max-width: 300px){
    .card{
        width: 100%;
        height: 400px;
        margin-top: 5px;
        
    }
    .card-container{
        margin-left: 2px;
    }
}