.contact-container{
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #192655;
}


.contact-container .header{
    text-align: center;
}
.header h2{
    position: relative;
}
.header h2::after{
    content: '';
    position: absolute;
    text-align: center;
    width: 45px;
    height: 3px;
    left: 48%;
    bottom: -1px;
    background-color: #ff6600;
}
.contact-box{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 20px;
    margin-right: 40px;
}
.social-container{
    padding-top: 10px;
  
}
.social-container h5{
    color: black;
    font-size: 14px;
    padding-top: 15px;
   
  
}
.social i{
    margin-top: 40px;
    padding-right: 20px;
    color: rgb(5, 5, 77);
    font-size: 18px;
    cursor: pointer;
    transition: .5s ease-in;
}
.social i:hover{
    color: #ff6600;
    transform: translate(5px, 5px);
}
.success{
    background-color: #FF8080;
    width: 80%;
    height: 40px;
    text-align: center;
    color: green;
    border-radius: 2px;
}
.about{
    margin-top: 10px;
    display: flex;
    width: 400px;
    justify-content: space-between;

}
.about2{
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    width: 400px;
    justify-content: space-between;

}
.phone i{
   font-size: 13px;
   font-style: normal;
   color: black;
   letter-spacing: 2px;
}
.about2 i{
    font-size: 13px;
    font-style: normal;
    color: black;
}
.form-error{
    display: block;
    font-size: 12px;
    color: red;
}
.form-container{
padding-top: 20px;
}
.form-control{
    display: block;
    margin-top: 10px;
    width: 80%;
    font-weight: 400;
}
input, textarea{
    padding: 5px;
    border: 2px solid #badef5;
    border-radius: 5px;

}

input:focus, textarea:focus{
    outline: none;
    border: 2px solid green;
}

form{
    width: 600px;
}
.btn-send{
    margin-top: 10px;
    border: none;
    background-color: crimson;
    padding: 8px 20px;
    color: white;
    cursor: pointer;
    font-weight: bold;
}
@media screen and (max-width: 700px){
    .contact-container{
        width: 100vw;
        overflow: hidden;
       
    }
    .contact-box{
        width: 80vw;
    }
    .about, .about2{
        width: 350px;
        justify-content: space-between;
    }
    .contact-box{
        flex-direction: column;
        width: 100vw;
    }
    
    form{
        width: 350px;
        margin-top: 10px;
    }
   .form-control{
    width: 100%;
    display: block;
   }
}
@media screen and (max-width: 400px){
    .social-container .about, .about2{
        width: 85vw;
    }
    .form-container form{
        width: 90vw;
    }
    .form-control{
        width: 100%;
    }
}